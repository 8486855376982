import React from "react";
import "./YesForm.css";
import googleLogo from "../assets/google-logo.png";
import facebookLogo from "../assets/facebook-logo.png";

export default function YesForm() {
  const reviewWebsites = [
    {
      img: googleLogo,
      link: "https://g.page/r/CVQ6ETWwHWUfEBM/review",
    },
    {
      img: facebookLogo,
      link: "https://www.facebook.com/ParkwayToyotaNJ/reviews",
    },
  ];
  return (
    <section id="yes-form">
      <div className="content">
        <div className="websites">
          {reviewWebsites.map((website, index) => (
            <a key={index} target="blank" href={website.link} className="logo">
              <img src={website.img} alt="" />
            </a>
          ))}
        </div>
        <p>
          Please share your experience at Parkway Toyota on any of
          the websites listed above.
        </p>
      </div>
    </section>
  );
}
