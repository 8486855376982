import React from "react";
import "./Header.css";
import logo from "./assets/toyota-logo.png";

export default function Header() {
  return (
    <div id="header">
      <div className="logo">
        <div className="logo-img">
          <img src={logo} alt="" />
        </div>
        <h2>Parkway Toyota</h2>
      </div>
      <div className="info">
        {/* <h1>Call Today: {"(201) 944-3300"}</h1>
        <h3>Service: {"(201)944-3300"}</h3> */}
        <p>50 Sylvan Avenue (9W) Englewood Cliffs, NJ 07632</p>
        <a target="blank" href="https://parkwaytoyota.com">Visit our website</a>
      </div>
    </div>
  );
}
