import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "./Recommend.css"

export default function Recommend() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("") 
  const handleYesClick = () => {
    setButtonClicked("yes")
    setSidebarOpen(true)
    // alert("Thank you for your feedback!");
    // Add any additional logic here
  };

  const handleNoClick = () => {
    setButtonClicked("no")
    setSidebarOpen(true)
    // alert("Thank you for your feedback!");
    // Add any additional logic here
  };

  const sidebarHandler = (e) => {
    e?.preventDefault()
    setSidebarOpen((current) => !current);
  };

  return (
    <>
      <Sidebar isOpen={sidebarOpen} formType={buttonClicked} sidebarHandler={sidebarHandler} />
      <div className="container">
        <h1>Would You Recommend Us to Your Family & Friends?</h1>
        <div className="button-container">
          <button className="yes-button" onClick={handleYesClick}>
            YES
          </button>
          <button className="no-button" onClick={handleNoClick}>
            NO
          </button>
        </div>
        <p>Please take a moment to tell us why or why not.</p>
      </div>
    </>
  );
}
