import Header from "./Header";
import "./MainPage.css";
import Footer from "./Footer";
import Recommend from "./Recommend";

export default function MainPage() {

  return (
    <div id="main-page">
      <Header />
      <Recommend />
      <Footer />
    </div>
  );
}
