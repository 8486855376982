import React, { useState } from "react";
import "./YesForm.css";
import "./NoForm.css";
import { addDoc, doc } from "firebase/firestore";
import { db } from "../auth/firebase";

export default function NoForm({closeHandler}) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    department: "sales",
    satisfaction: "excellent",
    caretaker: "",
    customerService: "excellent",
    facilities: "excellent",
    review: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    const res = await addDoc(doc(db, "reviewForNo"), formData)
    console.log("doc res", res)

    fetch("https://formsubmit.co/ajax/saguilar@gmail.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
    console.log(formData);
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Your Name *</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email *</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone * {"(xxx-xxx-xxxx)"}</label>
          <input
            type="tel"
            name="phone"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Department Rated *</label>
          <select
            name="department"
            value={formData.department}
            onChange={handleChange}
            required
          >
            <option value="sales">Sales</option>
            <option value="service">Service</option>
            <option value="parts">Parts</option>
            <option value="overall">Overall</option>
          </select>
        </div>
        <div>
          <label>Satisfaction/Rating *</label>
          <select
            name="satisfaction"
            value={formData.satisfaction}
            onChange={handleChange}
            required
          >
            <option value="excellent">Excellent</option>
            <option value="good">Good</option>
            <option value="average">Average</option>
            <option value="fair">Fair</option>
            <option value="poor">Poor</option>
          </select>
        </div>
        <div>
          <label>Who Took Care Of You?</label>
          <input
            type="text"
            name="caretaker"
            value={formData.caretaker}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Customer Service Rating *</label>
          <select
            name="customerService"
            value={formData.customerService}
            onChange={handleChange}
            required
          >
            <option value="excellent">Excellent</option>
            <option value="good">Good</option>
            <option value="average">Average</option>
            <option value="fair">Fair</option>
            <option value="poor">Poor</option>
          </select>
        </div>
        <div>
          <label>Facilities Rating *</label>
          <select
            name="facilities"
            value={formData.facilities}
            onChange={handleChange}
            required
          >
            {/* <option value=""></option> */}
            <option value="excellent">Excellent</option>
            <option value="good">Good</option>
            <option value="average">Average</option>
            <option value="fair">Fair</option>
            <option value="poor">Poor</option>
          </select>
        </div>
        <div>
          <label>Review</label>
          <textarea
            name="review"
            rows={5}
            value={formData.review}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="btns">
          <button className="close-btn" onClick={closeHandler}>Close</button>
          <button type="submit">Submit</button>
        </div>
      </form>
    </section>
  );
}
