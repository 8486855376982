import React from "react";
import "./Footer.css"

export default function Footer() {
  return (
    <div id="footer">
      <div className="footer-left">
        <p>&copy; 2024 Parkway Toyota, 50 Sylvan Avenue (9W) Englewood Cliffs, NJ 07632</p>
        <p>{"(201)944-3300"}</p>
      </div>
      <div className="footer-right">
        {/* <a href="/disclaimer">Disclaimer</a> |  */}
        <a target="blank" href="https://www.parkwaytoyota.com/">Privacy Policy</a>
      </div>
    </div>
  );
}